import { Divider, Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Paper>
        <Typography variant="h4" gutterBottom>
          Política de Privacidade
        </Typography>

        <Typography variant="body1">
          A equipe dos aplicativos JCB Bicho RJ, Nacional, Look e SP respeita
          sua privacidade e está comprometida em proteger as informações
          pessoais e sensíveis dos usuários. Esta política descreve como
          coletamos, usamos, compartilhamos e protegemos seus dados. Atualizada
          em Novembro/2024.
        </Typography>

        <Typography variant="h6">1. Dados Coletados</Typography>

        <Typography variant="body1">
          O aplicativo não coleta nenhum dado pessoal ou sensível, como
          identificadores de dispositivo, localização, ou informações de
          contato. No entanto, podemos registrar interações e dados de
          desempenho para melhorar a experiência do usuário.
        </Typography>

        <Typography variant="body1">
          Atividade do Aplicativo: Interações no aplicativo e histórico de
          pesquisa.
        </Typography>

        <Typography variant="body1">
          Informações e Desempenho do Aplicativo: Registros de falhas,
          diagnósticos e dados de desempenho.
        </Typography>

        <Typography variant="h6">2. Uso dos Dados</Typography>

        <Typography variant="body1">
          Os dados coletados são usados exclusivamente para:
        </Typography>

        <Typography variant="body1">
          Melhorar a experiência do usuário.
        </Typography>

        <Typography variant="body1">
          Diagnosticar e corrigir erros do aplicativo.
        </Typography>

        <Typography variant="body1">
          Analisar o desempenho do aplicativo.
        </Typography>

        <Typography variant="h6">3. Compartilhamento de Dados</Typography>

        <Typography variant="body1">
          Nenhum dado é compartilhado com terceiros.
        </Typography>

        <Typography variant="body1">
          As informações coletadas permanecem exclusivamente no escopo do
          aplicativo e não são vendidas ou fornecidas a terceiros.
        </Typography>

        <Typography variant="h6">4. Segurança de Dados</Typography>

        <Typography variant="body1">
          Os dados são criptografados durante a transmissão para garantir sua
          segurança.
        </Typography>

        <Typography variant="body1">
          Mantemos práticas adequadas para proteger suas informações contra
          acesso não autorizado, alteração ou divulgação.
        </Typography>

        <Typography variant="h6">5. Retenção e Exclusão de Dados</Typography>

        <Typography variant="body1">
          Os dados são retidos pelo tempo necessário para os fins descritos
          nesta política. Atualmente, não fornecemos uma maneira de solicitar
          exclusão de dados, mas estamos trabalhando para implementar essa
          funcionalidade em futuras atualizações.
        </Typography>

        <Typography variant="h6">6. Exclusividade e Público-Alvo</Typography>

        <Typography variant="body1">
          Este aplicativo é exclusivo para maiores de 18 anos e seu uso é
          restrito a finalidades de análise de dados históricos, culturais e
          estatísticos. Não promovemos, incentivamos ou facilitamos apostas ou
          jogos de azar.
        </Typography>

        <Typography variant="h6">7. Contato</Typography>

        <Typography variant="body1">
          Se você tiver dúvidas sobre esta política de privacidade ou precisar
          de mais informações, entre em contato conosco:
        </Typography>

        <Typography variant="body1">
          Email: suporte.jcbsistemas@gmail.com
        </Typography>

        <Typography variant="h6">8. Atualizações</Typography>

        <Typography variant="body1">
          Esta política pode ser atualizada periodicamente. Quaisquer mudanças
          serão publicadas nesta página.
        </Typography>

        <Divider />
        <Typography variant="body2">
          Equipe dos APPs JCB Bicho RJ, Nacional, Look e SP agradece
        </Typography>
        <Typography variant="body2">
          Atualizada em 19 de novembro de 2024
        </Typography>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
