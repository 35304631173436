import { Routes, Route, Navigate } from 'react-router';
import PrivacyPolicy from '../PrvicyPolicy';

export const AppReouters = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/privacy-policy" />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};
